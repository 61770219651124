// @file autolinker to transform text into text with links inside anchor tags
import type { link as linkFn } from '@@/bits/autolinkerjs'
import { captureMessage } from '@@/bits/error_tracker'
import { EMAIL_REGEX, TEL_REGEX } from '@@/bits/regex'
import { hasUrl } from '@@/bits/url'
import { BRIDGED_LINK_TYPE_ATTRIBUTE } from '@@/native_bridge/anchor'
import type { AutolinkerConfig } from 'autolinker'

const DEFAULT_OPTS = {
  // Open links in new window
  newWindow: true,
  // Don't remove "https://www." etc from link text
  stripPrefix: false,
  // Don't remove trailing slash
  stripTrailingSlash: false,
  urls: {
    // Avoid autolinking filenames
    tldMatches: false,
  },
}

export interface LinkMatch {
  type: 'email' | 'tel' | 'url'
  href: string
}

export function match(text: string): LinkMatch | null {
  if (text.match(EMAIL_REGEX) != null) {
    return { type: 'email', href: `mailto:${text}` }
  }
  const matches = text.match(TEL_REGEX)
  if (matches != null) {
    const num = matches[0].replace(/[^a-zA-z0-9\+]/g, '')
    return { type: 'tel', href: `tel:${num}` }
  }
  if (hasUrl(text)) {
    return { type: 'url', href: text }
  }
  return null
}

interface Autolinker {
  link: typeof linkFn
}

export async function link(text: string, opts?: AutolinkerConfig | undefined): Promise<string> {
  let autolinker: Autolinker
  try {
    autolinker = (await import('@@/bits/autolinkerjs')).default
    if (autolinker == null) {
      throw new Error('Autolinker not loaded')
    }
  } catch (error) {
    captureMessage('Autolinker not loaded', {
      level: 'warning',
      context: { url: window.location.href, error },
    })
    return text
  }
  try {
    return autolinker.link(text, Object.assign({}, DEFAULT_OPTS, opts ?? {}))
  } catch (error) {
    captureMessage('Error linking text', {
      level: 'error',
      context: { url: window.location.href, text, error },
    })
    return text
  }
}

// santize html tags before linking => only the link tags will work when passed to v-html
export async function sanitizedLink(text: string, opts?: AutolinkerConfig | undefined): Promise<string> {
  const sanitizeText = text
    .replace(/&/g, '&amp;') // Must happen first or else it will escape other just-escaped characters.
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')

  return await link(sanitizeText, opts)
}

export const autoLink = async (text: string, opts: AutolinkerConfig = {}): Promise<string> => {
  return await link(text, {
    replaceFn: (match) => {
      const tag = match.buildTag()
      tag.setAttr('rel', 'noopener nofollow ugc')
      tag.setAttr(BRIDGED_LINK_TYPE_ATTRIBUTE, 'external')
      return tag
    },
    ...opts,
  })
}
